import React, { useRef, useEffect } from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/bootstrap.min.css"
import "../styles/main.scss"

// hooks

const Layout = ({
  children,
  data,
  location,
  isContact,
  isSignup,
  isMigration,
}) => {
  //Hook to grab window size
  // const size = useWindowSize()
  // Ref for parent div and scrolling div
  // const app = useRef()
  // const scrollContainer = useRef()
  // Configs
  // const scrollData = {
  //   ease: 0.08,
  //   current: 0,
  //   previous: 0,
  //   rounded: 0,
  // }
  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver(() => {
  //     setBodyHeight()
  //
  //   })
  //   resizeObserver.observe(scrollContainer.current)
  // }, [])
  //set the height of the body.
  // useEffect(() => {
  //   setBodyHeight()
  // }, [size.height])
  // Run scrollrender once page is loaded.
  // useEffect(() => {
  //   requestAnimationFrame(() => skewScrolling())
  // })
  //Set the height of the body to the height of the scrolling div
  // const setBodyHeight = () => {
  //   if (scrollContainer.current) {
  //       "updating body height with scroll container",
  //       scrollContainer.current.getBoundingClientRect().height
  //     )
  //     document.body.style.height = `${
  //       scrollContainer.current.getBoundingClientRect().height
  //     }px`
  //   }
  // }

  // Scrolling
  // const skewScrolling = () => {
  //   //Set Current to the scroll position amount
  //   scrollData.current = window.scrollY
  //   // Set Previous to the scroll previous position
  //   scrollData.previous +=
  //     (scrollData.current - scrollData.previous) * scrollData.ease
  //   // Set rounded to
  //   scrollData.rounded = Math.round(scrollData.previous * 100) / 100
  //   // Difference between
  //   const difference = scrollData.current - scrollData.rounded
  //   const acceleration = difference / size.width
  //   const velocity = +acceleration
  //   const skew = velocity * 7.5
  //   //Assign skew and smooth scrolling to the scroll container
  //   if (scrollContainer.current) {
  //     scrollContainer.current.style.transform = `translate3d(0, -${scrollData.rounded}px, 0)`
  //   }
  //   //loop vai raf
  //   requestAnimationFrame(() => skewScrolling())
  // }

  const headerMode = data ? data.headerType : "light"
  return (
    <>
      <Header mode={headerMode} />
      <main>{children}</main>
      <Footer
        isSignup={isSignup}
        isContact={isContact}
        isMigration={isMigration}
      />
    </>
  )
}

export default Layout
