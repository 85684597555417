import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MobileMenu from "../components/mobile_menu"
import LogoBlack from "../images/coderapper-new-logo-black.svg"
import LogoWhite from "../images/coderapper-new-logo.svg"

const Header = ({ mode }) => {
  // window.addEventListener('scroll', function (event) {
  //   hasScrolled(this);
  // });
  // function hasScrolled(event) {
  //   var st = event.scrollY;
  //   // Make sure they scroll more than delta
  //   if (Math.abs(lastScrollTop - st) <= delta)
  //     return;
  //   // If they scrolled down and are past the navbar, add class .nav-up.
  //   // This is necessary so you never see what is "behind" the navbar.
  //   if (process.browser) {
  //     let headerElem = document.querySelector('header.header')
  //     if (st > lastScrollTop && st > navbarHeight) {
  //       // Scroll Down

  //       headerElem.classList.remove('nav-down');
  //       headerElem.classList.add('nav-up');
  //     } else {
  //       // Scroll Up
  //       if (st + window.innerHeight < document.documentElement.clientHeight) {
  //         headerElem.classList.remove('nav-up');
  //         headerElem.classList.add('nav-down');
  //       }
  //     }
  //   }
  //   lastScrollTop = st;
  // }

  const [header, setHeader] = useState(false)
  const listenScrollEvent = () => {
    if (mode == "transparent") {
      if (window.scrollY >= 1) {
        setHeader(true)
      } else {
        setHeader(false)
      }
    }
  }
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    window.addEventListener("scroll", listenScrollEvent)
  }

  return (
    <header
      style={
        mode == "dark"
          ? { backgroundColor: "#000000" }
          : mode == "transparent"
          ? { background: "none" }
          : { backgroundColor: "#ffffff" }
      }
      className={header ? "header scrolling-active" : "header"}
    >
      <div className="container-lg">
        <div className="row">
          <div className="col col-md-4 col-sm-5">
            <div className="site-logo">
              <Link to="/">
                {mode == "dark" ? (
                  <img src={LogoWhite} alt="site logo" />
                ) : (
                  <img src={LogoBlack} alt="site logo" />
                )}
              </Link>
            </div>
          </div>
          <div className="col col-md-8 col-sm-7 text-right">
            {/* <nav>
            <ul>
              <li>
                <Link>Covid-19</Link>
              </li>
              <li>
                <Link to="/solution">Solution</Link>
              </li>
              <li>
                <Link to="/work">Work</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav> */}
            <MobileMenu mode={mode}></MobileMenu>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
