import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
// icons
import IconFb from "../images/Icon-Fb.svg"
import IconIg from "../images/Icon-ig.svg"
import IconLdn from "../images/Icon-Linkedin.svg"

const MobileMenu = ({ mode }) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    if (mode == "transparent") {
      document.documentElement.classList.add("nospace")
      document.body.classList.add("nospace")
    } else {
      document.documentElement.classList.remove("nospace")
      document.body.classList.remove("nospace")
    }

    const listenScrollEvent = () => {
      if (window.scrollY > 0) {
        setMobHeader(true)
      } else {
        setMobHeader(false)
      }
    }
    window.addEventListener("scroll", listenScrollEvent)
  }
  const [mobHeader, setMobHeader] = useState(false)
  const [isActive, toggleMobileNav] = useState(false)

  useEffect(() => {
    if (!isActive) {
      document.body.style.overflowY = "visible"
    }
  }, [])

  function handleMenuToggle() {
    toggleMobileNav(!isActive)
    if (isActive) {
      document.body.style.overflowY = "visible"
    } else {
      document.body.style.overflowY = "overlay"
    }
  }

  return (
    <div className="mobile menu">
      <div className="wrap">
        <div
          className="trigger box"
          style={
            isActive
              ? { backgroundColor: "#fff" }
              : { backgroundColor: "transparent" }
          }
        >
          <div
            role="button"
            className={isActive ? "menu-bars active" : "menu-bars not-active"}
            id={mode == "transparent" ? "adds" : null}
            onClick={handleMenuToggle}
          >
            {mode == "dark" ? (
              <React.Fragment>
                <span style={{ backgroundColor: "#fff" }}></span>
                <span style={{ backgroundColor: "#fff" }}></span>
                <span style={{ backgroundColor: "#fff" }}></span>
              </React.Fragment>
            ) : mode != "dark" && mode != "light" && !mobHeader ? (
              <React.Fragment>
                <span style={{ backgroundColor: "#fff" }}></span>
                <span style={{ backgroundColor: "#fff" }}></span>
                <span style={{ backgroundColor: "#fff" }}></span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span style={{ backgroundColor: "#000" }}></span>
                <span style={{ backgroundColor: "#000" }}></span>
                <span style={{ backgroundColor: "#000" }}></span>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className={isActive ? "menu overlay active" : "menu overlay"}>
        <ul className="menu-content">
          <span>MENU</span>
          <li className="nav-item">
            <Link to="/projects/" activeClassName="active">
              Work
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/services/" activeClassName="active">
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/careers" activeClassName="active">
              Careers
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/blog" activeClassName="active">
              Blog
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" activeClassName="active">
              Contact
            </Link>
          </li>
          <li className="social-media">
            <div>
              <a href="https://www.facebook.com/coderapperIN/" target="_blank">
                <img className="fb" src={IconFb} alt="facebook" />
                <span>Facebook</span>
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/coderapper-llp/"
                target="_blank"
              >
                <img src={IconLdn} alt="Linkedin" />
                <span>Linkedin</span>
              </a>
            </div>
            <div>
              <a href="https://instagram.com/coderapper/" target="_blank">
                <img src={IconIg} alt="Instagram" />
                <span>Instagram</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileMenu
