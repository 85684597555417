import React from "react"
import CrButton from "../components/arrowButton"
import ClutchWidget from "./clutchWidget"

const handleSubmit = event => {
  event.preventDefault()

  let myForm = document.getElementById("contact-form")
  let formData = new FormData(myForm)

  fetch("/", {
    method: "POST",
    // mode: 'no-cors',
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(data => {
      let successTemplate = `<div className="notification" style="visibility: visible; opacity: 1;">
        <div className="notification-dialog">
          <h3><span className="icon"></span>Success !</h3>
          <p>All done! Thanks for reaching out. </p>
          <p>We'll get back to you soon!</p>
        </div>
      </div>`
      if (data.status == 200) {
        myForm.reset()
        document.querySelector(".notification-wrap").innerHTML = successTemplate
        setTimeout(function () {
          document.querySelector(".notification").remove()
        }, 3000)
      }
    })
    .catch(error => alert(error))
}

const FooterForm = props => {
  return (
    <div className="footer-form" id="CRfooter">
      <div className="container-lg">
        <div className="row left right">
          <div className="col-md-10">
            <div className="section-tag white">
              <span>Get in Touch</span>
            </div>
            <div className="header">
              Ready to discuss all things <br /> e-commerce? Let’s talk shop.
            </div>
            <div className="contact-details">
              <div>
                <a href="mailto:enquiry@coderapper.com">
                  hello@coderapper.com
                </a>
              </div>
              <div>
                <a href="tel:+(506) 230-1305">(506) 230-1305</a>
              </div>
            </div>
            {props.isContactPage && (
              <form
                className="contact-form"
                data-netlify-recaptcha="true"
                id="contact-form"
                data-netlify="true"
                name="contactForm"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-10">
                    <div className="mat-group">
                      <input type="text" name="name" required="required" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label htmlFor="name">Name*</label>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="mat-group">
                      <input type="text" name="email" required="required" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label htmlFor="email">Email*</label>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="mat-group">
                      <input type="text" name="phone" required="required" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label htmlFor="phone">Phone</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 gap-1x">
                  <div>
                    <div className="mat-group">
                      <label>What do you need help with?</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="md-checkbox">
                        <input
                          id="r1"
                          type="checkbox"
                          name="Store Design & development"
                        ></input>
                        <label htmlFor="r1">Store Design & development</label>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="md-checkbox">
                        <input
                          id="r2"
                          type="checkbox"
                          name="App Design & Development"
                        ></input>
                        <label htmlFor="r2">App Design & Development</label>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="md-checkbox">
                        <input
                          id="r3"
                          type="checkbox"
                          name="Conversion Rate Optimization"
                        ></input>
                        <label htmlFor="r3">Conversion Rate Optimization</label>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="md-checkbox">
                        <input
                          id="r4"
                          type="checkbox"
                          name="Digital Marketing & Growth"
                        ></input>
                        <label htmlFor="r4">Digital Marketing & Growth</label>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="md-checkbox">
                        <input
                          id="r5"
                          type="checkbox"
                          name="E-commerce performance audit"
                        ></input>
                        <label htmlFor="r5">E-commerce performance audit</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="mat-group">
                    <textarea
                      type="textarea"
                      rows="3"
                      required="required"
                      name="message"
                    ></textarea>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label htmlFor="message">
                      Tell us more about your project
                    </label>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="mat-group">
                    <select required name="refferal">
                      <option defaultValue></option>
                      <option value="Social media">Social media</option>
                      <option value="Google search">Google search</option>
                      <option value="Online ads">Online ads</option>
                      <option value="Blog content">Blog content</option>
                      <option value="Referral from a friend">
                        Referral from a friend
                      </option>
                    </select>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className="select-label" htmlFor="refferal">
                      How did you find out about us?
                    </label>
                  </div>
                </div>
                <div data-netlify-recaptcha="true"></div>
                <input
                  type="hidden"
                  name="form-name"
                  value="contactForm"
                ></input>

                <CrButton type="dark" btnType="submit" label="Send Message" />
                <div className="notification-wrap"></div>
              </form>
            )}
            {!props.isContactPage && (
              <div className="contact-link">
                <CrButton path="https://coderapper.com/contact/" type="dark" label="Get in touch" />
              </div>
            )}
          </div>
        </div>
        <div className="listings_link">
          <a class="list_link" href="https://coderapper.com/article/">
            Articles
          </a>{" "}
          |{" "}
          <a className="list_link" href="/listing">
            Listings
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterForm
