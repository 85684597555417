import React from "react"
import CrButton from "../components/arrowButton"

const handleSignUpSubmit = event => {
  event.preventDefault()
  let signUpFooterForm = document.getElementById("footer-sign-up-form")
  let formData = new FormData(signUpFooterForm)

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(data => {
      let successTemplate = `<div class="notification" style="visibility: visible; opacity: 1;">
        <div class="notification-dialog">
          <h3><span class="icon"></span>Success !</h3>
          <p>Thanks for reaching out. </p>
          <p>Our team will get in touch with you soon.</p>
        </div>
      </div>`
      if (data.status == 200) {
        signUpFooterForm.reset()
        document.querySelector(".notification-wrap").innerHTML = successTemplate
        setTimeout(function () {
          document.querySelector(".notification").remove()
        }, 3000)
      }
    })
    .catch(error => alert(error))
}

const footerSignUpForm = props => {
  return (
    <div className="footer-form foot-sign-up-form" id="LPfooter">
      <div className="container-lg">
        <div className="row">
          <div className="col-md-10">
            <div className="section-tag white">
              <span>Sign up</span>
            </div>
            {props.isMigrationPage == true && (
              <>
                <h2 className="footer-title">
                  Let’s get your migration started.
                </h2>
                <h2 className="footer-mob-title">Ready to get started?</h2>
              </>
            )}
            {props.isMigrationPage != true && (
              <h2>Save $6000 on Shopify Plus costs.</h2>
            )}
            <div className="sign-up-details"></div>
            <form
              className="footer-sign-up-form"
              data-netlify-recaptcha="true"
              id="footer-sign-up-form"
              data-netlify="true"
              name="footerSignUpForm"
              onSubmit={handleSignUpSubmit}
            >
              <div className="row extra-gutter right">
                <div className="col-md-5">
                  <div className="mat-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required="required"
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label htmlFor="name">Name*</label>
                  </div>
                </div>
                <div className="col-md-5 offset-md-1">
                  <div className="mat-group">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      required="required"
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label htmlFor="email">Email*</label>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="mat-group">
                    <input
                      type="text"
                      name="storeUrl"
                      id="storeUrl"
                      required="required"
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label htmlFor="storeUrl">Store Url*</label>
                  </div>
                </div>
                <div className="col-md-5 offset-md-1">
                  <div className="mat-group">
                    <select
                      required
                      name="currentPlatform"
                      id="currentPlatform"
                    >
                      <option defaultValue hidden></option>
                      <option value="Shopify Advanced">Shopify Advanced</option>
                      <option value="Shopify Regular">Shopify Regular</option>
                      <option value="Magento CE">Magento CE</option>
                      <option value="Magento Enterprise">
                        Magento Enterprise
                      </option>
                      <option value="WooCommerce">WooCommerce</option>
                    </select>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className="select-label" htmlFor="currentPlatform">
                      Current Platform*
                    </label>
                  </div>
                </div>
                <div className="col-lg-5 col-md-11">
                  <div className="mat-group mat-group1">
                    <select required name="gmv" id="gmv">
                      <option defaultValue hidden></option>
                      <option value="$500,000-$750,000">
                        $500,000-$750,000
                      </option>
                      <option value="$750,000-$1M">$750,000-$1M</option>
                      <option value="$1M and above">$1M and above</option>
                    </select>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className="select-label" htmlFor="gmv">
                      Select your GMV*
                    </label>
                  </div>
                </div>
                <div className="col-lg-5 offset-lg-1 col-md-11">
                  <div className="mat-group">
                    <select required name="migration" id="migration">
                      <option defaultValue hidden></option>
                      <option value="Less than 1 month">
                        Less than 1 month
                      </option>
                      <option value="1-2 months">1-2 months</option>
                      <option value="> 3 months"> &gt; 3 months</option>
                    </select>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className="select-label" htmlFor="migration">
                      When are you planning the migration?*
                    </label>
                  </div>
                </div>
                <div className="col-md-11">
                  <div className="mat-group">
                    <textarea
                      type="textarea"
                      rows="3"
                      required="required"
                      name="bottleneck"
                      id="bottleneck"
                    ></textarea>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label htmlFor="bottleneck">
                      What is your current operational bottleneck? Please be
                      detailed.
                    </label>
                  </div>
                </div>
              </div>
              <div data-netlify-recaptcha="true"></div>
              <input
                type="hidden"
                name="footer-SignUpForm"
                value="footerSignUpForm"
              ></input>

              <CrButton type="dark" btnType="submit" label="Sign up" />
              <div className="notification-wrap"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default footerSignUpForm
