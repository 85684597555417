import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import ArrowBlack from "../images/right-arrow-black.svg"
import ArrowWhite from "../images/right-arrow-white.svg"
import { TweenMax, Power3 } from "gsap/all"

const CrButton = ({
  type,
  path,
  label,
  btnType,
  className,
  download,
  onClick,
  target,
  arrow
}) => {
  let pharallaxRing = new useRef(null)
  let pharallaxArrow = new useRef(null)

  function scaleRing() {
    TweenMax.to(pharallaxRing.current, 0.8, { scale: 1.2 })
  }

  function warpRingWithCursor(e) {
    e.persist()
    handleWarpEffect(e, pharallaxRing.current, 18)
    handleWarpEffect(e, pharallaxArrow.current, 8)
  }

  function resetRing() {
    TweenMax.to([pharallaxRing.current, pharallaxArrow.current], 0.8, {
      scale: 1,
      x: 0,
      y: 0,
    })
  }

  function handleWarpEffect(e, target, movement) {
    let ringWidth = pharallaxRing.current.getBoundingClientRect().width
    let ringHeight = pharallaxRing.current.getBoundingClientRect().height
    let relX = e.clientX - pharallaxRing.current.getBoundingClientRect().left
    let relY = e.clientY - pharallaxRing.current.getBoundingClientRect().top
    TweenMax.to(target, 0.8, {
      x: ((relX - ringWidth / 2) / ringWidth) * movement,
      y: ((relY - ringHeight / 2) / ringHeight) * movement,
      ease: Power3.easeOut,
    })
  }

  return (
    <div>
      {!btnType ? (
        <Link to={path ? path : "/"}>
          <div
            className={
              type === "light"
                ? `cr-arrow-btn black ${className ? className : ""}`
                : `cr-arrow-btn white ${className ? className : ""}`
            }
            onMouseEnter={scaleRing}
            onMouseMove={warpRingWithCursor}
            onMouseLeave={resetRing}
          >
            <span className="btn-text">{label}</span>
            <div ref={pharallaxRing}>
              <div className={`parallax ring ${arrow === 'down' ? 'arrow-down': ''}`}>
                <img
                  ref={pharallaxArrow}
                  src={type === "light" ? ArrowBlack : ArrowWhite}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </Link>
      ) : download || target ? (
        <a
          href={path}
          {...(download ? { download: "Case_Study" } : {})}
          {...(target ? { target: "_blank" } : {})}
        >
          <div
            className={
              type === "light"
                ? `cr-arrow-btn black ${className ? className : ""}`
                : `cr-arrow-btn white ${className ? className : ""}`
            }
            onMouseEnter={scaleRing}
            onMouseMove={warpRingWithCursor}
            onMouseLeave={resetRing}
            onClick={onClick}
          >
            <span className="btn-text">{label}</span>
            <div ref={pharallaxRing}>
              <div className="parallax ring">
                <img
                  ref={pharallaxArrow}
                  src={type === "light" ? ArrowBlack : ArrowWhite}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </a>
      ) : (
        <button
          className={`cr-btn ${className ? className : ""} `}
          type={btnType}
        >
          <div
            className={
              type === "light" ? "cr-arrow-btn black" : "cr-arrow-btn white"
            }
            onMouseEnter={scaleRing}
            onMouseMove={warpRingWithCursor}
            onMouseLeave={resetRing}
            onClick={onClick}
          >
            <span className="btn-text">{label}</span>
            <div ref={pharallaxRing}>
              <div className="parallax ring">
                <img
                  ref={pharallaxArrow}
                  src={type === "light" ? ArrowBlack : ArrowWhite}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </button>
      )}
    </div>
  )
}

export default CrButton
