/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, article, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteURL
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = title || site.siteMetadata.title
  const siteURL = url ? url : "https://coderapper.com"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        (article ? true : null)
          ? title
          : defaultTitle
          ? `${defaultTitle}`
          : null
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: siteURL,
        },
        {
          property: `twitter:type`,
          content: `website`,
        },
        {
          name: `og:url`,
          content: siteURL,
        },
      ].concat(meta)}
    >
      {siteURL && <meta property="og:url" content={siteURL} />}
      {<meta property="og:type" content="website" />} */}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {<meta name="twitter:card" content="summary_large_image" />}
      {<meta property="og:image" content={image} />}
      {<meta name="twitter:image:src" content={image} />}
      {site.siteMetadata?.author && (
        <meta property="twitter:creator" content={site.siteMetadata?.author} />
      )}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {description && <meta name="twitter:url" content={siteURL} />}
      {description && <meta name="twitter:image:src" content={image} />}
      {description && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {/* {(article ? true : null) && (
        <meta property="twitter:type" content="article" />
      )} */}
      {/* {image && <meta property="og:image" content={image.src ? image.src : ''} />} */}
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
//   title: ``,
//   image: null,
//   article: false,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
//   article: PropTypes.bool,
//   image: PropTypes.string,
// }

export default SEO
