import React from "react"
import FooterForm from "./footerForm"
import FooterSignUpForm from "./footerSignUpForm"

const Footer = ({ isContact, isSignup, isMigration }) => (
  <footer>
    {isContact != true && isSignup != true && <FooterForm />}
    {isSignup == true && isMigration != true && <FooterSignUpForm />}
    {isSignup == true && isMigration == true && (
      <FooterSignUpForm isMigrationPage={true} />
    )}
    <div className="bottom">
      <div className="container-lg">
        <div className="row left right">
          <div className="col-md-8">
            <div className="locations">
              <span>New York</span>
              <span>Fredericton</span>
              <span>Bangalore</span>
              <span>Chennai</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="social-links">
              <a href="https://www.facebook.com/coderapperIN/" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/coderapper-llp/"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://instagram.com/coderapper/" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
              <a href=" https://medium.com/coderapper" target="_blank">
                <i className="fab fa-medium"></i>
              </a>
              <a href=" https://www.behance.net/coderapper" target="_blank">
                <i className="fab fa-behance"></i>
              </a>
              <a
                href="https://dribbble.com/coderapper_official"
                target="_blank"
              >
                <i className="fab fa-dribbble"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="last left right">
          CodeRapper is a division of Sedin Technologies
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
